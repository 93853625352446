import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Template from '../templates/events-template'

const EventsIndex = (props) => <Template {...props} />

EventsIndex.propTypes = {
  data: PropTypes.object.isRequired
}

export default EventsIndex

export const pageQuery = graphql`
  query eventsEnQuery {
    settings: allContentfulSettings(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...Settings
        }
      }
    }
    events: allContentfulEvent(
      filter: {
        node_locale: { eq: "en-US" }
      }
      sort: {
        fields: [start]
      }
    ) {
      edges {
        node {
          ...EventLink
        }
      }
    }
  }
`
